/**
* Created by tudou on 2020/4/12 18:38.
*/
<template>
  <div class="base-content">
    <query-frame
      :current="page.current"
      :total="page.total"
      @on-query="handleQuery"
      @on-reset="handleReset"
      @on-add="handleAdd"
      @on-page-change="handlePageChange"
      @on-size-change="handleSizeChange"
    >
      <!-- 查询条件开始 -->
      <template slot="query">
        <query-item label="门店名称" v-if="isStoreUser">
          <store-list-select v-model="query.shopId"></store-list-select>
        </query-item>
        <query-item label="姓名">
          <a-input v-model="query.realName" placeholder="姓名"></a-input>
        </query-item>
        <query-item label="手机号">
          <a-input v-model="query.mobile" placeholder="手机号"></a-input>
        </query-item>
        <query-item label="注册时间">
          <i-date-picker v-model="query.duration"></i-date-picker>
        </query-item>
      </template>
      <!-- 查询条件结束 -->

      <!-- 查询结果 开始 -->
      <template slot="table">
        <i-table
          :columns="columns"
          :dataSource="dataList"
          @selectRowChange="selectRowChange"
        >
        </i-table>
      </template>
      <!-- 查询结果 结束 -->
      <template slot="btns">
        <a-button
          class="mr-10"
          type="primary"
          icon="download"
          @click="membersExport"
          >导出</a-button
        >
        <a-button
          class="mr-10"
          type="primary"
          icon="download"
          @click="membersCoupon"
          >下发优惠劵</a-button
        >
      </template>
    </query-frame>
    <a-modal
      v-model="userCouponVisible"
      width="500px"
      height="600px"
      ref="couponmodal"
      :maskClosable="false"
      @ok="handleSendUser"
    >
      <div>
        <div :style="{ borderBottom: '1px solid #E9E9E9' }">选择优惠劵</div>
        <br />
        <div style="width: 100%">
          <j-select-multiple
            style="width: 100%"
            v-model="currentCheckedList"
            :options="userPlainOptions"
          />
        </div>
      </div>
      <br />
      <div>
        <a-table
          size="small"
          bordered
          :rowKey="'value'"
          :pagination="false"
          :scroll="{ y: 150 }"
          :columns="couponsColumns"
          :data-source="couponDatasoure"
        ></a-table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import moment from 'moment';
import {
  memberList,
  memberStatus,
  membersExport,
  memberRefund,
} from '@/api/member';
import queryMixin from '@/mixin/queryMixin';
import IDatePicker from '../../components/IDatePicker.vue';
import { getValidCouponList, addUserCoupon } from '@/api/coupon';
import JSelectMultiple from '@/components/JSelectMultiple';

export default {
  name: 'MemberList',
  mixins: [queryMixin],
  components: {
    IDatePicker,
    JSelectMultiple,
  },
  props: {},
  data() {
    return {
      query: {
        shopId: null,
        realName: null,
        phone: null,
        duration: [],
      },
      couponDatasoure: [],
      couponsColumns: [
        {
          title: '优惠劵名称',
          dataIndex: 'label',
          key: 'label',
          ellipsis: true,
          width: 150,
        },
        {
          title: '数量',
          dataIndex: 'cnt',
          key: 'cnt',
          ellipsis: true,
          width: 100,
          customRender: (text, row, index) => {
            return (
              <section>
                <a-input-number
                  {...{
                    attrs: {
                      min: 0,
                      step: 1,
                      max: 100,
                      value: text,
                    },
                  }}
                  vOn:change={(val) => this.handleChange(val, row)}
                  style="width: 100%;"
                ></a-input-number>
              </section>
            );
          },
        },
      ],
      // 默认展示表格
      columns: [
        {
          title: '门店名称',
          dataIndex: 'shopName',
          key: 'shopName',
          ellipsis: true,
          width: 200,
        },
        {
          title: '姓名',
          dataIndex: 'realName',
          key: 'realName',
          width: 120,
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          key: 'mobile',
          ellipsis: true,
          width: 150,
        },
        {
          title: '总余额（元）',
          dataIndex: 'amount',
          key: 'amount',
          width: 120,
        },
        {
          title: '实充余额（元）',
          dataIndex: 'realAmount',
          key: 'realAmount',
          width: 120,
        },
        {
          title: '赠送余额（元）',
          dataIndex: 'giftAmount',
          key: 'giftAmount',
          width: 120,
        },
        {
          title: '可退金额（元）',
          dataIndex: 'remainAmount',
          key: 'remainAmount',
          width: 120,
        },
        {
          title: '剩余次数（次）',
          dataIndex: 'count',
          key: 'count',
          width: 120,
        },
        {
          title: '会员等级',
          dataIndex: 'levelName',
          key: 'levelName',
          width: 120,
        },
        {
          title: '累计金额（元）',
          dataIndex: 'aggregateAmount',
          key: 'aggregateAmount',
          width: 120,
        },
        {
          title: '折扣',
          dataIndex: 'discount',
          key: 'discount',
          width: 120,
        },
        {
          title: '积分',
          dataIndex: 'integral',
          key: 'integral',
          width: 120,
        },
        {
          title: '会员卡号',
          dataIndex: 'memberCode',
          key: 'memberCode',
          ellipsis: true,
          width: 120,
        },
        {
          title: '性别',
          dataIndex: 'sexName',
          key: 'sexName',
          width: 60,
        },
        // {
        //   title: '年龄',
        //   dataIndex: 'age',
        //   key: 'age',
        //   width: 100,
        // },
        {
          title: '注册时间',
          dataIndex: 'createTime',
          key: 'createTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '状态',
          dataIndex: 'status',
          key: 'status',
          width: 150,
          customRender: (text, row, index) => {
            return (
              <a-switch
                checked-children="启用"
                un-checked-children="禁用"
                checked={row.status === 0}
                onClick={() => this.handleStatus(row)}
              />
            );
          },
        },
        {
          title: '结束时间',
          dataIndex: 'endTime',
          key: 'endTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '会费起始时间',
          dataIndex: 'feeStartTime',
          key: 'feeStartTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '会费终止时间',
          dataIndex: 'feeEndTime',
          key: 'feeEndTime',
          ellipsis: true,
          width: 180,
        },
        {
          title: '会费折扣',
          dataIndex: 'feeDiscount',
          key: 'feeDiscount',
          ellipsis: true,
          width: 180,
        },
        {
          title: '操作',
          dataIndex: 'action',
          key: 'action',
          fixed: 'right',
          width: 515,
          scopedSlots: { customRender: 'action' },
          customRender: (text, row, index) => {
            return (
              <section>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.handleInfo(row)}
                >
                  查看
                </a-button>
                <a-divider type="vertical"></a-divider>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.handleEdit(row)}
                >
                  编辑
                </a-button>
                <a-divider type="vertical"></a-divider>
                <a-button
                  type="link"
                  size="small"
                  onClick={() => this.handleMatch(row)}
                >
                  成绩
                </a-button>
                <span>
                  <a-divider type="vertical"></a-divider>
                  <a-button
                    type="link"
                    size="small"
                    onClick={() => this.handleBill(row)}
                  >
                    消费记录
                  </a-button>
                </span>
                <span>
                  <a-divider type="vertical"></a-divider>
                  <a-button
                    type="link"
                    size="small"
                    onClick={() => this.handleTimesCard(row)}
                  >
                    次卡记录
                  </a-button>
                </span>
                <span>
                  <a-divider type="vertical"></a-divider>
                  <a-button
                    type="link"
                    size="small"
                    onClick={() => this.handleDiscount(row)}
                  >
                    会员折扣
                  </a-button>
                </span>
                <span v-has="member-refund">
                  <a-divider type="vertical"></a-divider>
                  <a-button
                    type="link"
                    size="small"
                    v-show={row.remainAmount > 0}
                    onClick={() => this.handleRefund(row)}
                  >
                    退款
                  </a-button>
                </span>
              </section>
            );
          },
        },
      ],
      dataList: [],
      userPlainOptions: [],
      currentCheckedList: '',
      memberids: '',
      userCouponVisible: false,
    };
  },
  computed: {
    isStoreUser() {
      const userInfo = this.$store.getters.getUserInfo;
      return userInfo.userDTO.shopId === 0;
    },
  },
  watch: {
    currentCheckedList(newVal, oldVal) {
      console.log(newVal);
      this.addCouponTable();
    },
  },
  created() {
    this.handleQuery();
    this.getCoupon();
  },
  mounted() {},
  destroyed() {},
  methods: {
    selectRowChange(data) {
      this.memberids = data.toString();
    },
    addCouponTable() {
      const arr = this.currentCheckedList.split(',');
      const TmpArr = [];
      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        let bfind = false;
        for (let k = 0; k < this.couponDatasoure.length; k++) {
          if (item === this.couponDatasoure[k].value) {
            TmpArr.push(this.couponDatasoure[k]);
            bfind = true;
            break;
          }
        }
        // 没有找到
        if (!bfind) {
          for (let k = 0; k < this.userPlainOptions.length; k++) {
            if (item === this.userPlainOptions[k].value) {
              const insertitem = this.userPlainOptions[k];
              insertitem.cnt = 1;
              TmpArr.push(insertitem);
              break;
            }
          }
        }
      }
      this.couponDatasoure = JSON.parse(JSON.stringify(TmpArr));
    },
    handleChange(value, row) {
      row.cnt = value;
    },
    handleSendUser() {
      for (let i = 0; i < this.couponDatasoure.length; i++) {
        const couponid = this.couponDatasoure[i].value;
        const param = new FormData();
        param.append('userids', this.memberids);
        param.append('allflag', 0);
        param.append('cnt', this.couponDatasoure[i].cnt);
        param.append('couponid', couponid);
        console.log('------------handleSendUser----------');
        const that = this;
        addUserCoupon(param).then((res) => {
          that.userCouponVisible = false;
        });
      }
    },
    getCoupon() {
      console.log('loadValidCouponList');
      const that = this;
      this.userPlainOptions = [];
      const param = {};
      const datestr = moment(new Date()).format('YYYY-MM-DD');
      getValidCouponList(param).then((res) => {
        for (let i = 0; i < res.length; i++) {
          const kitem = res[i];
          if (
            kitem.endflag == 1
            && kitem.endTime !== undefined
            && kitem.endTime < datestr
          ) continue;
          const item = {};
          item.value = res[i].id;
          item.label = res[i].name;
          that.userPlainOptions.push(item);
        }
      });
    },
    membersCoupon() {
      // this.currentCheckedList = [];
      // this.couponDatasoure = [];
      if (this.memberids == '') {
        this.$message.warning('请选择要下发的人员');
        return;
      }
      this.userCouponVisible = true;
    },
    // 获取会员列表
    getData() {
      this.params.pageIndex = this.page.current;
      this.params.pageSize = this.page.pageSize;

      if (this.params.duration && this.params.duration.length) {
        this.params.startDate = this.params.duration[0];
        this.params.endDate = this.params.duration[1];
        delete this.params.duration;
      }
      this.dataList = [];
      memberList(this.params).then((data) => {
        const { totalCount, list } = data;
        this.page.total = totalCount;
        list.forEach((item) => {
          item.key = item.memberId;
          item.id = item.memberId;
        });
        this.dataList = list;
      });
    },

    // 添加会员
    handleAdd() {
      this.$router.push({
        name: 'memberAdd',
      });
    },
    // 查看详情
    handleInfo(row) {
      this.$router.push({
        name: 'memberInfo',
        params: {
          id: row.memberId,
        },
      });
    },
    // 编辑
    handleEdit(row) {
      this.$router.push({
        name: 'memberEdit',
        params: {
          id: row.memberId,
        },
      });
    },
    // 会员折扣
    handleDiscount(row) {
      this.$router.push({
        name: 'memberDiscount',
        params: {
          id: row.memberId,
        },
      });
    },
    // 编辑
    handleMatch(row) {
      this.$router.push({
        name: 'memberMatch',
        params: {
          mobile: row.mobile,
        },
      });
    },
    // 启用或者禁用会员
    handleStatus(row) {
      const params = {
        memberId: row.memberId,
        status: row.status === 0 ? 1 : 0,
      };

      memberStatus(params).then(() => {
        this.$message.success('操作成功！');
        this.getData();
      });
    },
    membersExport() {
      membersExport().then((data) => {
        window.open(data);
      });
    },
    // 编辑
    handleBill(row) {
      this.$router.push({
        name: 'memberBill',
        params: {
          memberId: row.memberId,
        },
      });
    },
    // 次卡
    handleTimesCard(row) {
      this.$router.push({
        name: 'memberTimesCard',
        params: {
          memberId: row.memberId,
        },
      });
    },
    // 退款
    handleRefund(row) {
      const self = this;
      this.$confirm({
        title: '会员退款',
        content: `可退金额${row.remainAmount}元，您确定退款吗？`,
        okType: 'danger',
        onOk() {
          const params = {
            memberId: row.memberId,
            amount: -row.remainAmount,
            amountDiscount: 0, // 优惠金额
            amountPay: -row.remainAmount, // 应付金额
            discountWay: 0, // 优惠方式 0:无优惠 根据订单类型 优惠方式不一样
            discountWayRamarks: '',
            mobile: row.mobile,
            remarks: '会员退款',
          };
          return new Promise((resolve, reject) => {
            console.log('退款');
            memberRefund(params)
              .then(() => {
                self.$message.success('退款成功');
                self.getData();
                resolve();
              })
              .catch(() => {
                reject();
              });
          }).catch((e) => console.log(e));
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="less" scoped>
</style>
